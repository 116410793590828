// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./form/common.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .admin.container {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
body .admin.container .endpoint {
  padding: 10px;
  background-color: bisque;
  display: grid;
}
body .admin.container .endpoint .form-group {
  display: flex;
  flex-direction: column-reverse;
}
body .admin.container .endpoint .methods {
  padding: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
}
body .admin.container .active {
  filter: invert(1);
}

button {
  padding: 5px;
  border-radius: 5px;
  border-style: inset;
}
button:hover {
  border-style: outset;
}`, "",{"version":3,"sources":["webpack://./src/styles/admin.scss"],"names":[],"mappings":"AAGI;EACI,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,aAAA;EACA,sBAAA;EAgBA,SAAA;AAhBR;AACQ;EACI,aAAA;EAMA,wBAAA;EACA,aAAA;AAJZ;AAFY;EACI,aAAA;EACA,8BAAA;AAIhB;AACY;EACI,mBAAA;EACA,aAAA;EACA,8BAAA;AAChB;AAGQ;EACI,iBAAA;AADZ;;AAKA;EACI,YAAA;EACA,kBAAA;EACA,mBAAA;AAFJ;AAGI;EACI,oBAAA;AADR","sourcesContent":["@import url(\"./form/common.scss\");\nbody{\n    // background-color: bisque;\n    .admin.container{\n        background-color: #fff;\n        padding:10px;\n        border-radius:10px;\n        box-shadow: 0 0 10px ;\n        display: flex;\n        flex-direction: column;\n        .endpoint{\n            padding:10px;\n            .form-group{\n                display: flex;\n                flex-direction: column-reverse;\n\n            }\n            background-color: bisque;\n            display:grid;\n            .methods{\n                padding:10px 0 0 0;\n                display:flex;\n                justify-content: space-between;\n            }\n        }\n        gap:20px;\n        .active{\n            filter:invert(1)\n        }\n    }\n}\nbutton{\n    padding:5px;\n    border-radius:5px;\n    border-style:inset;\n    &:hover{\n        border-style:outset;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
