// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.features {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
@media screen and (min-width: 480px) {
  .features {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1024px) {
  .features {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1600px) {
  .features {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
.features .card {
  border: 1px solid black;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.features .card .dp {
  display: flex;
}
.features .card .dp img {
  width: 100%;
}
.features .card .name {
  color: #fff;
  background-color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/home-features.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,8BAAA;EAUA,SAAA;AARJ;AADI;EAJJ;IAKQ,kCAAA;EAIN;AACF;AAHA;EAPA;IAQI,sCAAA;EAMF;AACF;AALA;EAVA;IAWI,8CAAA;EAQF;AACF;AANI;EACI,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;AAQR;AAPQ;EACI,aAAA;AASZ;AARY;EACI,WAAA;AAUhB;AAPQ;EACI,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,kBAAA;EACA,cAAA;AASZ","sourcesContent":[".features{\n    padding:20px;\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    @media screen and (min-width:480px){\n        grid-template-columns: 1fr 1fr 1fr;\n}\n@media screen and (min-width:1024px) {\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n}\n@media screen and (min-width:1600px) {\n    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;\n}\n    gap:10px;\n    .card{\n        border:1px solid black;\n        border-radius: 5px;\n        position: relative;\n        overflow: hidden;\n        .dp{\n            display:flex;\n            img{\n                width:100%;\n            }\n        }\n        .name{\n            color:#fff;\n            background-color: #000;\n            position: absolute;\n            bottom:0;\n            left:0;\n            right:0;\n            text-align: center;\n            padding:5px 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
