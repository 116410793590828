// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categories {
  padding: 30px;
  flex-wrap: wrap;
  gap: 20px;
  display: grid;
  justify-content: space-around;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 620px) {
  .categories {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1024px) {
  .categories {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1600px) {
  .categories {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1920px) {
  .categories {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 2560px) {
  .categories {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 3840px) {
  .categories {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
.categories .category {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: var(--secondary);
  background-color: var(--secondary-background);
  padding: 20px;
  max-width: 500px;
  min-width: 250px;
  gap: 20px;
  cursor: pointer;
}
.categories .category .icon {
  font-size: 48px;
}
.categories .category:hover {
  background-color: var(--secondary);
  color: var(--secondary-background);
  box-shadow: 0 0 10px black;
}`, "",{"version":3,"sources":["webpack://./src/styles/home-categories.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,SAAA;EACA,aAAA;EACA,6BAAA;EACA,0BAAA;AACJ;AAAI;EAPJ;IAQQ,8BAAA;EAGN;AACF;AAFI;EAVJ;IAWQ,kCAAA;EAKN;AACF;AAJI;EAbJ;IAcQ,sCAAA;EAON;AACF;AANI;EAhBJ;IAiBQ,0CAAA;EASN;AACF;AARI;EAnBJ;IAoBQ,8CAAA;EAWN;AACF;AAVI;EAtBJ;IAuBQ,kDAAA;EAaN;AACF;AAZI;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,uBAAA;EACA,6CAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,SAAA;EACA,eAAA;AAcR;AAbQ;EACI,eAAA;AAeZ;AAbQ;EACI,kCAAA;EACA,kCAAA;EACA,0BAAA;AAeZ","sourcesContent":[".categories{\n    padding:30px;\n    flex-wrap: wrap;\n    gap:20px;\n    display: grid;\n    justify-content: space-around;\n    grid-template-columns: 1fr;\n    @media screen and (min-width: 620px) {\n        grid-template-columns: 1fr 1fr;\n    }\n    @media screen and (min-width:1024px) {\n        grid-template-columns: 1fr 1fr 1fr;\n    }\n    @media screen and (min-width:1600px) {\n        grid-template-columns: 1fr 1fr 1fr 1fr;\n    }\n    @media screen and (min-width:1920px){\n        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n    }\n    @media screen and (min-width:2560px) {\n        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;\n    }\n    @media screen and (min-width:3840px) {\n        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;\n    }\n    .category{\n        display: flex;\n        flex-direction: column;\n        text-align: center;\n        color:var(--secondary);\n        background-color: var(--secondary-background);\n        padding:20px;\n        max-width:500px;\n        min-width:250px;\n        gap:20px;\n        cursor: pointer;\n        .icon{\n            font-size:48px;\n        }\n        &:hover{\n            background-color: var(--secondary);\n            color: var(--secondary-background);\n            box-shadow: 0 0 10px black;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
