import React from "react";

export default function MemberZone(){
    return(<div className="member-zone">
        <a href="/user/login">
            <i className="fa fa-user"></i>
           <span>
            My Account
            </span> 
        </a>
    </div>)
}