// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/images/banner/banner-1.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
  background-color: #fff;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  padding: 40px;
}
.banner .message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  padding: 100px 20px;
  color: var(--primary-background);
  gap: 20px;
}
.banner .message h1 {
  font-size: 40px;
}
.banner .message h2 {
  font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/styles/home-banner.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,mDAAA;EACA,sBAAA;EACA,aAAA;AACJ;AAAI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,uBAAA;EACA,8BAAA;EACA,mBAAA;EACA,gCAAA;EACA,SAAA;AAER;AADQ;EACI,eAAA;AAGZ;AADQ;EACI,eAAA;AAGZ","sourcesContent":[".banner{\n    background-color: #fff;\n    background:url(\"../../public/images/banner/banner-1.jpg\");\n    background-size:cover;\n    padding:40px;\n    .message{\n        display:flex;\n        flex-direction:column;\n        align-items: center;\n        text-align: center;\n        justify-content: center;\n        background:rgba(0,0,0,0.4);\n        padding:100px 20px;\n        color:var(--primary-background);\n        gap:20px;\n        h1{\n            font-size: 40px;\n        }\n        h2{\n            font-size:24px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
